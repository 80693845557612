import "./bootstrap";
import "../css/app.css";
import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createPinia } from "pinia";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import * as Sentry from "@sentry/vue";
import Vue3Toastify from "vue3-toastify";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import "vue3-toastify/dist/index.css";

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";
const pinia = createPinia();

import.meta.glob(["../images/**"]);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    errorCaptured: (error) => {
        if (error.message.includes("Failed to fetch dynamically imported module")) {
            // refresh the page if a dynamic import fails
            window.location.reload(true);
        }
        return true;
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(VueGoogleMaps, {
                load: {
                    key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
                },
            })
            .use(Vue3Toastify, {
                autoClose: 1000,
                position: "top-right",
            })
            .use(ZiggyVue);
        const dsn = import.meta.env.VITE_SENTRY_DSN;
        const release = import.meta.env.VITE_RELEASE;
        if (dsn && dsn !== "null") {
            Sentry.init({
                app: vueApp,
                dsn,
                release,
                integrations: [
                    new Sentry.BrowserTracing({
                        tracePropagationTargets: [`https://${import.meta.env.VITE_APP_DOMAIN}`],
                    }),
                    new Sentry.Replay({
                        maskAllText: false,
                        maskAllInputs: false,
                    }),
                ],
                tracesSampleRate: 0.1,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }
        return vueApp.mount(el);
    },
    progress: {
        color: "#E50019",
    },
});
